/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Dispute Status
.status-class 
{
  border-radius: 100px 100px / 100px;
  width: fit-content;
}
.class1
{
    background-color: #d6e8f5;  //open
    color: #004079;
}
.class2
{
    background-color: #d9f2df;  //app
    color: #005216;
}
.class3
{
    background-color: #f8d8d8;  //rej
    color: #aa0000;
}
.class4
{
    background-color: #f2f2f2;  //pscu
    color: #000000;
}
